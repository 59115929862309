<template>
  <div class="actRecovery" @click.stop>
    <div class="navBar">
      <div @click="$router.go(-1)" class="back">
        <svg-icon class="leftBtn" iconClass="back"></svg-icon>
        <span>返回</span>
      </div>
      <div class="title">账号找回</div>
      <div class="rightBtn"></div>
    </div>
    <div class="methodItem">
      <div class="text">账号凭证找回</div>
      <div class="rightBox">
        <span>如果你保存了手机凭证</span>
        <svg-icon class="icon" iconClass="right_arrow"></svg-icon>
        <input class="upload" type="file" @change="uploadQrcode($event)" />
      </div>
    </div>
    <router-link tag="div" to="/phoneRecovery" class="methodItem">
      <div class="text">手机号找回</div>
      <div class="rightBox">
        <span>如果你绑定了手机号</span>
        <svg-icon class="icon" iconClass="right_arrow"></svg-icon>
      </div>
    </router-link>
    <router-link tag="div" to="/kefu" class="methodItem">
      <div class="text">联系客服找回</div>
      <div class="rightBox">
        <span class="pink">在线客服</span>
        <svg-icon class="icon" iconClass="right_arrow"></svg-icon>
      </div>
    </router-link>
    <FullScreenLoading :isLoadingShow="isLoadingShow" />
  </div>
</template>

<script>
import qrcode from '@/assets/js/llqrcode';
import { loginGuest } from '@/api/user';
import { Toast } from 'vant';
import { setSessionItem, setLocalItem } from '@/utils/longStorage';
import FullScreenLoading from '@/components/FullScreenLoading';

export default {
  name: 'ActRecovery',
  data() {
    return {
      isLoadingShow: false,
    };
  },
  components: {
    FullScreenLoading,
  },
  methods: {
    // 上传解析二维码
    async uploadQrcode(e) {
      this.isLoadingShow = true;
      try {
        let base64 = await this.compress(e.target.files[0]);
        // eslint-disable-next-line no-undef
        qrcode.decode(base64);
        // eslint-disable-next-line no-undef
        qrcode.callback = (imgMsg) => {
          this.login(imgMsg);
        };
      } catch (e) {
        this.isLoadingShow = false;
        Toast('二维码识别失败');
      }
    },
    // 压缩图片
    compress(file) {
      let selt = this;
      if (!file || !window.FileReader) return;
      if (/^image/.test(file.type)) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        return new Promise((resolve, reject) => {
          reader.onloadend = function () {
            let result = reader.result;
            let img = new Image();
            img.src = result;
            img.onload = function () {
              let canvas = document.createElement('canvas');
              let ctx = canvas.getContext('2d');
              let width = img.width;
              let height = img.height;
              canvas.width = width;
              canvas.height = height;
              // 铺底色
              ctx.fillStyle = '#fff';
              ctx.fillRect(0, 0, canvas.width, canvas.height);
              ctx.drawImage(img, 0, 0, width, height);
              //进行最小压缩
              let ndata = canvas.toDataURL('image/jpeg', 0.5);
              resolve(ndata);
            };
          };
        });
      }
    },
    //登陆
    async login(qrurl) {
      try {
        let ret = await this.$Api(loginGuest, {
          content: qrurl,
        });
        this.isLoadingShow = false;
        if (ret && ret.code === 200) {
          Toast('登录成功');
          setSessionItem('userInfo', JSON.stringify(ret.data));
          setLocalItem('token', ret.data.token);
          this.$store.dispatch('commit/SET_USERINFO', ret.data);
          this.$router.go(-1);
        } else {
          Toast(ret.tip || '登录失败');
        }
      } catch (error) {
        this.isLoadingShow = false;
        Toast('登录失败');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.actRecovery {
  height: 100%;
  .navBar {
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    background-color: #fff;
    margin-bottom: 16px;
    box-sizing: border-box;
    border-bottom: 1px solid rgb(217, 217, 217);
    .back {
      width: 80px;
      display: flex;
      align-items: center;

      .leftBtn {
        width: 24px;
        height: 24px;
        font-size: 24px;
      }

      span {
        font-size: 18px;
      }
    }

    .title {
      flex: 1;
      font-size: 18px;
      text-align: center;
      color: #000;
    }

    .rightBtn {
      width: 80px;
    }
  }

  .methodItem {
    height: 62px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(217, 212, 231, 0.1);
    background-color: #fff;
    padding: 0 13px;
    .upload {
      position: absolute;
      width: 100%;
      height: 62px;
      opacity: 0;
    }
    .rightBox {
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        color: rgb(153, 153, 153);
        font-size: 14px;
      }
      .pink {
        color: rgb(255, 103, 143);
      }
    }
    .icon {
      margin-left: 5px;
      height: 20px;
      width: 20px;
    }
  }
  .methodItem:last-of-type {
    border-bottom: unset;
  }
}
</style>
